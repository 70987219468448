const Spanish = {
  PublicHeader: {
    btn_help: 'ayuda',
  },
  Login: {
    btn_login: 'acceso',
    btn_signup: 'inscribirse',
    lbl_email: 'email',
    lbl_password: 'contraseña',
    txt_forgot_password: '¿olvidé mi contraseña?',
    txt_forgot_email: '¿Olvidaste tu Email?',
    txt_no_account: '¿No tienes una cuenta?',
  }
};

export default Spanish;