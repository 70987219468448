import { POSTrequest } from 'utils/helpers/api_handler';
import {
  INITIALIZE_STORE,
  LOGIN,
  AUTHENTICATE_ACCOUNT,
  BROWSER_WARNING,
  MOBILE_WARNING,
  LANGUAGE_CHANGE,
  SEARCH_VEHICLE,
  CREATE_PASSWORD,
  TOGGLE_PERMIT_ADD,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
} from 'view/constants';
  
/////////////////////////////// AUTHENTICATION //////////////////////////////////
export function userLogin(body) {
  // const response = POSTrequest('/authentication/login', body);
  return {
    type: LOGIN,
    payload: body
  };
}

export function createPassword(body) {
  const response = POSTrequest('/auth/create-password', body);
  return {
    type: CREATE_PASSWORD,
    payload: response,
  };
}

export function authenticateAccount(body) {
  // const response = POSTrequest('/authentication/login', body);
  return {
    type: AUTHENTICATE_ACCOUNT,
    payload: body
  };
}

export function userLogout() {
  // const response = POSTrequest('/authentication/logout', body);
  return {
    type: INITIALIZE_STORE,
  };
}

export function changeLanguage(lang) {
  return {
    type: LANGUAGE_CHANGE,
    payload: lang,
  };
}

export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function setMobileWarning() {
  return {
    type: MOBILE_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function toggleLoaderOn() {
  return {
    type: TOGGLE_LOADER_ON,
  };
}

export function toggleLoaderOff() {
  return {
    type: TOGGLE_LOADER_OFF,
  };
}

export function searchVehicle(payload) {
  return {
    type: SEARCH_VEHICLE,
    payload,
  };
}





export function togglePermitAdd(isAddingPermit) {
  return {
    type: TOGGLE_PERMIT_ADD,
    payload: isAddingPermit,
  };
}