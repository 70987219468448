import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TranslationHandler from 'utils/translations/translation_handler';

import { 
  Box,
  List,
  ListItem,
  Divider,
  Slide,
} from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';

import styles from 'view/private/footer/index.module.css';


export class SettingsMenu extends Component {
  
  static propTypes = {
    isSessionOpen: PropTypes.bool.isRequired,
    handleSessionMenu: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    text: PropTypes.shape({
      SessionMenu: PropTypes.shape({
        lbl_documents: PropTypes.string,
        lbl_account_info: PropTypes.string,
        lbl_logout: PropTypes.string,
      }),
    }).isRequired,
  };
  
  render() {
    return this.props.isSessionOpen ? this.renderSettingsMenu() : null;
  }

  renderSettingsMenu() {
    const { isSessionOpen, text } = this.props;
    return (
      <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper', position: 'fixed', bottom: '56px' }} id='topBox'>
        <Slide direction='up' in={isSessionOpen}>
          <List disablePadding>
            <Divider />
            <ListItem>
              <div className={styles.subNav} onClick={() => this.props.logOut()}>
                <div className={styles.subNavBtn}>
                  <LogoutIcon color='primary' />
                </div>
                <div className={styles.subNavLabel}>{text.SessionMenu.lbl_logout}</div>
              </div>
            </ListItem>
          </List>
        </Slide>
      </Box>
    );
  }
}

//export default withRouter(TranslationHandler(SettingsMenu));
export default withRouter(connect(null, null)(TranslationHandler(SettingsMenu)));