export const fakePlateNumberList = ['r4t5y6', 'y6y7i8', '7u8i9o', '1q2w3e', '6y7u8i'];

export const fakeVinNumberList = ['123456', '234567', '987654', '456789', '765409'];

export const fakePropertyList = [
  { property_id: 0, property_name: 'River Dale' },
  { property_id: 1, property_name: 'Millville' },
  { property_id: 2, property_name: 'HankyVille' },
];

//One active permit
export const fakeSearchResults = [{ 
  permit_type: 'active', 
  vehicle: '2023 FORD F-150', 
  plate: '1q2w3e',
  vin: '123456', 
  permit_id: '09876', 
  start_date: '12/34/56 @ 7:89 PM MST', 
  end_date: '12/34/56 @ 7:89 PM MST', 
  parking: 'Open Lot' 
}];

//Two active permit
/*export const fakeSearchResults = [
  { 
    permit_type: 'active', 
    vehicle: '2023 FORD F-150', 
    plate: '1q2w3e',
    vin: '123456', 
    permit_id: '09876', 
    start_date: '12/34/56 @ 7:89 PM MST', 
    end_date: '12/34/56 @ 7:89 PM MST', 
    parking: '1'
  },

  { 
    permit_type: 'active', 
    vehicle: '2023 FORD F-150', 
    plate: '1q2w3e',
    vin: '123456', 
    permit_id: '09876', 
    start_date: '12/34/56 @ 7:89 PM MST', 
    end_date: '12/34/56 @ 7:89 PM MST', 
    parking: '2' 
  },
];*/

//No permits found
//export const fakeSearchResults = [];

//One expired permit
/*export const fakeSearchResults = [{ 
  permit_type: 'expired', 
  vehicle: '2023 FORD F-150', 
  plate: '1q2w3e',
  vin: '123456', 
  permit_id: '09876', 
  start_date: '12/34/56 @ 7:89 PM MST', 
  end_date: '12/34/56 @ 7:89 PM MST', 
  parking: 'expired' 
}];*/

//One cancelled permit
/*export const fakeSearchResults = [{ 
  permit_type: 'cancelled', 
  vehicle: '2023 FORD F-150', 
  plate: '1q2w3e',
  vin: '123456', 
  permit_id: '09876', 
  start_date: '12/34/56 @ 7:89 PM MST', 
  end_date: '12/34/56 @ 7:89 PM MST', 
  parking: 'cancelled' 
}];*/