import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LANGUAGES } from 'view/constants';

import { default as English } from 'utils/translations/en.js';
import { default as Spanish } from 'utils/translations/es.js';

const select = state => ({
  language: state.session.language
});

export default function(ComposedComponent) {

  class Translation extends React.Component {

    static propTypes = {
      language: PropTypes.string.isRequired
    };

    render() {
      const translatedText = this.props.language === LANGUAGES.EN ? English : Spanish; // eslint-disable-line
      return <ComposedComponent {...this.props} text={translatedText} />;
    }
  }

  return connect(select)(Translation);
}