export const buttons = {
  add: 'add',
  agree: 'i Agree',
  agree_terms: 'I agree to the terms and conditions',
  goback: 'go back',
  begin: 'begin',
  cancel: 'cancel',
  change: 'change',
  close: 'close',
  confirm: 'confirm',
  continue: 'continue',
  create: 'create',
  delete: 'delete',
  done: 'done',
  edit: 'edit',
  filter: 'filter',
  finish: 'finish',
  learn_more: 'learn more',
  next: 'next',
  ok: 'ok',
  print: 'print',
  read_terms: 'terms and conditions',
  register: 'register',
  reject: 'reject',
  remove: 'remove',
  revoke: 'revoke',
  save: 'save',
  send: 'send',
  search: 'search',
  skip: 'skip',
  submit: 'submit',
  toggle_password: 'toggle password visibility',
  unlink: 'unlink',
  update: 'update',
  validate: 'validate',
  view: 'view',
};