import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';

import { PERMIT_TYPE } from 'view/private/body/constants';

import { togglePermitAdd } from 'view/actions';

import { Button } from '@mui/material';

import styles from 'view/private/body/PermitCheck/index.module.css';


const select = state => ({
  vehicleInfo: state.session.vehicleInfo,
  searchResults: state.session.searchResults,
});

export class PermitCheck extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    vehicleInfo: PropTypes.object.isRequired,
    searchResults:  PropTypes.array.isRequired,
    text: PropTypes.shape({
      PermitCheck: PropTypes.shape({
        txt_search_query: PropTypes.string,
        lbl_plate: PropTypes.string,
        lbl_vin: PropTypes.string,
        txt_time_of_search: PropTypes.string,
        btn_back: PropTypes.string,
        txt_active: PropTypes.string,
        txt_no_permit: PropTypes.string,
        txt_expired: PropTypes.string,
        txt_canceled: PropTypes.string,
        lbl_card_plate: PropTypes.string,
        lbl_card_vin: PropTypes.string,
        lbl_card_permit_id: PropTypes.string,
        lbl_card_start_date: PropTypes.string,
        lbl_card_end_date: PropTypes.string,
        lbl_card_can_park: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { text, vehicleInfo, searchResults } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.backButton}>
          <Button
            variant='contained'
            size='large'
            style={{ height: '55px', width: '100%' }}
            onClick={() => this.backToSearch()}
          >
            {text.PermitCheck.btn_back}
          </Button>
        </div>
        <div className={styles.community}>
          {vehicleInfo.property_name}
        </div>
        <div className={styles.query}>{text.PermitCheck.txt_search_query}</div>
        <div className={styles.plateTxt}>
          {text.PermitCheck.lbl_plate}
        </div>
        <div className={styles.plateField}>
          {vehicleInfo.plate}
        </div>
        <div className={styles.vinTxt}>
          {text.PermitCheck.lbl_vin}
        </div>
        <div className={styles.vinField}>
          {vehicleInfo.vin}
        </div>
        <div className={styles.timeTxt}>{text.PermitCheck.txt_time_of_search}</div>
        <div className={styles.timeField}>{vehicleInfo.searchTimeStamp}</div>
        <div className={styles.results}>
          {
            (searchResults.length === 0) 
              ? 
              <div>
                <div className={styles.noPermitFound }>NO PERMIT FOUND</div>
              </div>
              : 
              searchResults.map(result => {
                switch (result.permit_type) {
                  case PERMIT_TYPE.ACTIVE: {
                    return (
                      <div className={styles.resultsContainer} key={result.permit_id}>
                        <div className={styles.active}>ACTIVE</div>
                        <div className={styles.vehicle}>{result.vehicle}</div>
                        <div className={styles.plate}>Plate | {result.plate}</div>
                        <div className={styles.vin}>VIN | {result.vin}</div>
                        <div className={styles.permit}>Permit ID | {result.permit_id}</div>
                        <div className={styles.startDate}>Start Date: {result.start_date}</div>
                        <div className={styles.endDate}>Expiration Date: {result.end_date}</div>
                        <div className={styles.parking}>Can park in: <b>{result.parking}</b></div>
                      </div>
                    );
                  }
                  case PERMIT_TYPE.EXPIRED: {
                    return (
                      <div className={styles.resultsContainer} key={result.permit_id}>
                        <div className={styles.expiredOrCancelled}>EXPIRED</div>
                        <div className={styles.vehicle}>{result.vehicle}</div>
                        <div className={styles.plate}>Plate | {result.plate}</div>
                        <div className={styles.vin}>VIN | {result.vin}</div>
                        <div className={styles.permit}>Permit ID | {result.permit_id}</div>
                        <div className={styles.startDate}>Start Date: {result.start_date}</div>
                        <div className={styles.endDate}>Expiration Date: {result.end_date}</div>
                        <div className={styles.noParking} />
                      </div>
                    );
                  }
                  case PERMIT_TYPE.CANCELLED: {
                    return (
                      <div className={styles.resultsContainer} key={result.permit_id}>
                        <div className={styles.expiredOrCancelled}>CANCELLED</div>
                        <div className={styles.vehicle}>{result.vehicle}</div>
                        <div className={styles.plate}>Plate | {result.plate}</div>
                        <div className={styles.vin}>VIN | {result.vin}</div>
                        <div className={styles.permit}>Permit ID | {result.permit_id}</div>
                        <div className={styles.startDate}>Start Date: {result.start_date}</div>
                        <div className={styles.endDate}>Expiration Date: {result.end_date}</div>
                        <div className={styles.noParking} />
                      </div>
                    );
                  }
                  default: 
                    return null;
                }
              })
          }
        </div>
      </div>
    );
  }

  backToSearch() {
    this.props.togglePermitAdd(false);
    this.props.history.push('/home');
  }
}

export default withRouter(connect(select, { togglePermitAdd })(TranslationHandler(PermitCheck)));
