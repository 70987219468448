import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import EmailInput from 'utils/SmartForm/components/EmailInput';

import styles from 'view/public/ForgotPassword/ForgotPasswordForm/index.module.css';

export class FormPasswordForm extends Component {

  static propTypes = {
    text: PropTypes.shape({
      ForgotPassword: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_send: PropTypes.string,
        lbl_email: PropTypes.string,
        txt_title: PropTypes.string,
        txt_confirm: PropTypes.string,
        txt_link: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
  };

  static contextType = SmartFormContext;

  render() {
    const { text } = this.props;
    return (
      <div className={styles.formContainer}>
        <LoadingOverlay
          show={false}
          width='100%'
        > 
          <form onSubmit={this.forgotPasswordSubmit}>
            <div className={styles.Container}>
              <div className={styles.title}><p />{text.ForgotPassword.txt_title}</div>
              <div className={styles.explanation}><p>{text.ForgotPassword.txt_link}</p></div>
              <div className={styles.email}>
                <EmailInput
                  inputName='email'
                  isRequired={true}
                  labelText={text.ForgotPassword.lbl_email}
                  style={{ width: '300px' }}
                  autoComplete={'off'}
                  inputFocus={true}
                />
              </div>
              <div className={styles.btn}>
                <Button
                  type='submit'
                  style={{ width: '150px' }}
                  onClick={() => this.props.history.push('/')}
                >
                  {text.ForgotPassword.btn_cancel}
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  style={{ width: '150px' }}
                  onClick={e => this.forgotPasswordSubmit(e)}
                >
                  {text.ForgotPassword.btn_send}
                </Button>
              </div>
            </div>
          </form>              
        </LoadingOverlay>
      </div>
    );
  }

  forgotPasswordSubmit(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      //const formattedList = this.context.prepInputList(validObj.inputList);
      //alert(JSON.stringify(formattedList));
      this.props.history.push('/');
      // this.props.history.push('/forgot-password');
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

}

export default withRouter(connect(null, {})(TranslationHandler(FormPasswordForm)));