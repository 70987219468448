import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import parking_tree from 'utils/theme/parking_tree';

import Private from 'view/private';
import Public from 'view/public';

import Alerts from 'utils/components/alerts';
import { Loader } from 'utils/components/Loader';

const select = state => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoader: state.session.isLoader,
});

export class App extends Component {

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isLoader: PropTypes.bool.isRequired,
  };

  render() {
    const view = this.props.isAuthenticated
      ? <Private isLoader={this.props.isLoader} />
      : <Public isLoader={this.props.isLoader} />;
    
    return (
      <ThemeProvider theme={createTheme(parking_tree)}>
        {view}
        <Alerts />
        <Loader isLoader={this.props.isLoader} />
      </ThemeProvider>
    );
  }
}

export default connect(select, { })(App);