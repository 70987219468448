import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TranslationHandler from 'utils/translations/translation_handler';

import styles from 'view/private/header/index.module.css';

export class PrivateHeader extends Component {

  static propTypes = {
    handleSessionMenu: PropTypes.func.isRequired,
    isSettingMenuOpen: PropTypes.bool.isRequired,
  };

  state = {
    isLoading: false,
  };

  render() {
    const { isSettingMenuOpen } = this.props;
    const blurStyles = isSettingMenuOpen ? 'blur(2px)' : 'unset' ;
    return (
      <div
        className={styles.container}
        style={{ filter: blurStyles }}
        onClick={this.props.handleSessionMenu}
      >
        <div className={styles.logo}>
          <img src={ require('utils/media/emblem.png') } alt='The Parking Tree' height='40px' />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(TranslationHandler(PrivateHeader)));