import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual, cloneDeep } from 'lodash';

import { 
  Alert,
  AlertTitle,
  Stack,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { properCase } from '@timber-rider/parkingtree-client-common';

import { ALERT_TYPES } from 'utils/components/alerts/constants';
import { removeAlert } from 'utils/components/alerts/actions';

import styles from 'utils/components/alerts/index.module.css';

const select = state => ({
  alertList: state.alerts.alertList,
});

export class Alerts extends Component {
  
  static propTypes = {
    alertList: PropTypes.array.isRequired,
    removeAlert: PropTypes.func.isRequired,
  };

  state = {
    existingList: [],
  };
  
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.alertList, this.props.alertList)) {
      const filteredList = this.props.alertList.filter(alert => alert.severity !== ALERT_TYPES.ERROR);
      filteredList.forEach(alert => {
        const existingList = cloneDeep(this.state.existingList);
        const hasExisting = existingList.some(existing => existing === alert.id);
        if (hasExisting) {
          existingList.slice(alert.id, 1);
          this.setState({ existingList });
        }
        else {
          existingList.push(alert.id);
          this.setState(
            { existingList },
            () => setTimeout(() => this.props.removeAlert(alert.id), 4000)
          );
        }
      });
    }
  }

  render() {
    return (
      <Stack 
        sx={{ position: 'absolute', top: '20%', left: '10%', right: '10%', zIndex: '99' }} 
        spacing={2}
        className={styles.fader}
      >
        {this.renderAlerts()}
      </Stack>
    );
  }

  renderAlerts() {
    return this.props.alertList?.map(alert => {
      
      let action = '';
      if (alert.severity === ALERT_TYPES.ERROR) {
        action = (
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => this.props.removeAlert(alert.id)}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        );
      }
      else {
        action = <IconButton />;
      }
      
      return (
        <Alert 
          severity={alert.severity} 
          key={alert.id} 
          style={{ textAlign: 'left', border: '1px solid #AED6F1', borderRadius: '10px' }}
          action={action}
        >
          <AlertTitle>{properCase(alert.severity)}</AlertTitle>
          {alert.message}
        </Alert>
      );
    });
  }
}

export default connect(select, { removeAlert })(Alerts);