import { buttons } from 'utils/translations/common';

const English = {
  Home: {
    txt_select_community: 'Select Community',
    txt_please_select_community: 'Select a Community',
    txt_search_vehicle: 'Search Vehicle',
    btn_search: 'Search',
    lbl_plate: 'Plate #',
    lbl_vin: 'Last 6 of VIN',

  },
  Login: {
    btn_login: 'login',
    lbl_email: 'email',
    lbl_password: 'password',
    txt_enforcement_app: 'ENFORCEMENT APP',
    txt_forgot_password: 'forgot password',
  },
  SessionMenu: {
    lbl_documents: 'documents',
    lbl_billing_info: 'billing info',
    lbl_account_info: 'account info',
    lbl_logout: 'logout',
  },
  PermitCheck: {
    txt_search_query: 'Search Query:',
    lbl_plate: 'Plate #:',
    lbl_vin: 'VIN #:',
    txt_time_of_search: 'Time of Search:',
    btn_back: 'Back to Search',

    txt_active: 'ACTIVE',
    txt_no_permit: 'NO PERMIT FOUND',
    txt_expired: 'EXPIRED',
    txt_canceled: 'CANCELED',
    lbl_card_plate: 'Plate',
    lbl_card_vin: 'VIN',
    lbl_card_permit_id: 'Permit ID',
    lbl_card_start_date: 'Start Date:',
    lbl_card_end_date: 'Expiration Date:',
    lbl_card_can_park: 'Can park in:',
  },
  ForgotPassword: {
    btn_cancel: buttons.cancel,
    btn_send: buttons.send,
    lbl_email: 'email',
    txt_title: 'forgot password',
    txt_confirm: 'If you do not receive a text within 10 minutes, please contact support.',
    txt_link: 'We will email you a link and a One Time Password so you can reset your password.',
  },
};

export default English;