import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import Home from 'view/private/body/Home/index';
import PermitCheck from 'view/private/body/PermitCheck/index';

export class Resident extends Component {

  render() {
    return (
      <Switch>
        <Route exact path='/home' render={props => (<Home {...props} />)} />
        <Route exact path='/home/permit-check' render={props => (<PermitCheck {...props} />)} />
      </Switch>
    );
  }
}

export default withRouter(Resident);