import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import HomeForm from 'view/private/body/Home/HomeForm/index';
import * as Helpers from 'view/private/body/Home/smart_form_helper';

export class Home extends Component {

  render() {
    return (
      <SmartFormProvider Helpers={Helpers}>
        <HomeForm />
      </SmartFormProvider>
    );
  }

}

export default Home;