import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TranslationHandler from 'utils/translations/translation_handler';
import { cloneDeep, isEmpty } from 'lodash';

import { Button } from '@mui/material';

import { searchVehicle, togglePermitAdd } from 'view/actions';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import DropdownInput from 'utils/SmartForm/components/DropdownInput';
import LicensePlateInput from 'utils/SmartForm/components/LicensePlateInput';
import VINInput from 'utils/SmartForm/components/VINInput';

import { searchTypes } from 'view/private/body/Home/smart_form_helper';

import styles from 'view/private/body/Home/HomeForm/index.module.css';

const select = state => ({
  vehicleInfo: state.session.vehicleInfo,
  propertyList: state.session.propertyList,
  plateNumberList: state.session.plateNumberList,
  vinNumberList: state.session.vinNumberList,
  selectedProperty: state.session.selectedProperty,
});

export class HomeForm extends Component {
  
  static propTypes = {
    togglePermitAdd: PropTypes.func.isRequired,
    searchVehicle: PropTypes.func.isRequired,
    vehicleInfo: PropTypes.object.isRequired,
    plateNumberList: PropTypes.array.isRequired,
    vinNumberList: PropTypes.array.isRequired,
    propertyList: PropTypes.array.isRequired,
    text: PropTypes.shape({
      Home: PropTypes.shape({
        txt_select_community: PropTypes.string,
        txt_please_select_community: PropTypes.string,
        txt_search_vehicle: PropTypes.string,
        btn_search: PropTypes.string,
        lbl_plate: PropTypes.string,
        lbl_vin: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    searchType: searchTypes.BOTH,
  };
  
  static contextType = SmartFormContext;

  componentDidMount() {
    const { vehicleInfo } = this.props;
    if (!isEmpty(vehicleInfo)) {
      const inputList = cloneDeep(this.context.inputList);
      inputList.forEach(input => {
        if (input.inputName === 'property') {
          input.inputValue = vehicleInfo.property;
        }
      });
      this.context.updateInputList(inputList);
    }
  }

  render() {
    const { text, propertyList, plateNumberList, vinNumberList } = this.props;
    const { searchType } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.selectCommunity}>{text.Home.txt_select_community}</div>
        <div className={styles.propertySelector}>
          <DropdownInput
            inputName='property'
            isRequired={true}
            labelText={text.Home.txt_please_select_community}
            style={{ width: '100%', height: '75px' }}
            options={propertyList.map(property => ({
              value: property.property_id,
              display: property.property_name,
            }))}
          />
        </div>
        <div className={styles.searchVehicleTxt}>{text.Home.txt_search_vehicle}</div>
        <div className={styles.plateField}>
          <LicensePlateInput 
            inputName='plate'
            isRequired={false}
            isDisabled={searchType === searchTypes.VIN}
            labelText={text.Home.lbl_plate}
            style={{ height: '75px', width: '200px' }}
            fieldChange={plateVal => this.setState({ searchType: plateVal })}
            options={plateNumberList}
          />
        </div>
        <div className={styles.plateBtn}>
          <Button
            variant='contained'
            size='large'
            style={{ height: '55px', width: '100px' }}
            onClick={() => this.search()}
            disabled={searchType === searchTypes.VIN}
          >
            {text.Home.btn_search}
          </Button>
        </div>
        <div className={styles.vinField}>
          <VINInput
            inputName='vin'
            isRequired={false}
            isDisabled={searchType === searchTypes.PLATE}
            labelText={text.Home.lbl_vin}
            style={{ height: '75px', width: '200px' }}
            fieldChange={vinVal => this.setState({ searchType: vinVal })}
            options={vinNumberList}
          />
        </div>
        <div className={styles.vinBtn}>
          <Button
            variant='contained'
            style={{ height: '55px', width: '100px' }}
            size='large'
            onClick={() => this.search()}
            disabled={searchType === searchTypes.PLATE}
          >
            {text.Home.btn_search}
          </Button>
        </div>
      </div>
    );
  }

  search() {
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const preppedObj = this.context.prepInputList(validObj.inputList, true);
      this.props.searchVehicle(preppedObj);
      this.props.history.push('/home/permit-check');
      this.props.togglePermitAdd(true);
    }
    else {
      this.context.updateInputList(validObj.inputList);
    } 
  }
}

export default withRouter(connect(select, { 
  searchVehicle,
  togglePermitAdd,
})(TranslationHandler(HomeForm)));
