import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { persistErrors } from 'utils/SmartForm/error_handler';
import { cloneDeep } from 'lodash';

import { REQUIRED } from 'utils/SmartForm/constants';

export const SmartFormContext = React.createContext();

export class SmartFormProvider extends Component {

  static propTypes = {
    Helpers: PropTypes.object,
    feature: PropTypes.string, // name of the feature to go on data-testid for each input
  };

  static defaultProps = {
    feature: 'Feature',
  };
  
  state = {
    inputList: [],
    formObject: {},
  };

  render() {
    const { inputList, formObject } = this.state;
    const { Helpers, feature, } = this.props;
    return (
      <SmartFormContext.Provider value={{
        inputList,
        formObject,
        Helpers,
        createTestId: (inputName) => `auto-${feature}-${inputName}Input`, // generates data-testid for each input
        addInput: (inputList, inputName, isRequired) => this.addInput(inputList, inputName, isRequired),
        updateInput: (inputList, inputName, inputValue, isValid) => this.updateInput(inputList, inputName, inputValue, isValid),
        removeInput: (inputList, inputName) => this.removeInput(inputList, inputName),
        updateInputList: inputList => this.updateInputList(inputList),
        addInlineMsg: (inputList, input) => this.addInlineMsg(inputList, input),
        checkRequiredInputs: inputList => this.checkRequiredInputs(inputList),
        prepInputList: inputList => this.prepInputList(inputList),
        allInputsHaveVal: (arrInputNames, inputProperty) => this.allInputsHaveVal(arrInputNames, inputProperty),
        helperTextManage: inputName => this.helperTextManage(inputName),
        helperErrorManage: inputName => this.helperErrorManage(inputName),
        clearGivenInputValues: (inputList, givenInputs) => this.clearGivenInputValues(inputList, givenInputs),
        clearAllInputValues: inputList => this.clearAllInputValues(inputList),
        updateInputRequirement: (inputList, inputName, isRequired) => this.updateInputRequirement(inputList, inputName, isRequired),
        findInputByName: (inputName) => this.findInputByName(inputName),
      }}
      >
        {this.props.children}
      </SmartFormContext.Provider>
    );
  }

  /**
  * Add a Smart Input to inputList
  * 
  * @param string inputName
  * @param bool isRequired
  * 
  * @returns the array index of the newly added input
  */
  addInput = (inputList, inputName, isRequired) => {
    try {
      let inputIndex = inputList.findIndex(input => input.inputName === inputName);
      if (inputIndex === -1) {
        inputList.push({ inputName, inputValue: '', isRequired });
        inputIndex = inputList.findIndex(input => input.inputName === inputName);
        this.setState({ inputList });
      }
      return inputIndex;
    }
    catch (error) {
      persistErrors(error);
    }
  };

  /**
  * Update a single Smart Input to inputList
  * 
  * @param array inputList
  * @param string inputName
  * @param string inputValue
  */
  updateInput(inputList, inputName, inputValue) {
    try {
      const inputIndex = inputList.findIndex(input => input.inputName === inputName);
      inputList[inputIndex].inputValue = inputValue;
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Remove a Smart Input from inputList
  * 
  * @param array inputList
  * @param numeric inputIndex
  * 
  * @returns the array index of the newly added input
  */
  removeInput(inputList, inputName) {
    try {
      if (inputList && inputList.length > 0 && inputName) {
        const inputIndex = inputList.findIndex(input => input.inputName === inputName);
        inputList.splice(inputIndex, 1);
        this.setState({ inputList });
      }
    }
    catch (error) {
      persistErrors(error);
    }
  }
  
  /**
  * Update the entire List of Input
  * 
  * @param Array list of Smart Inputs
  */
  updateInputList(inputList) {
    try {
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Prepare Smart Form data for inputList to be sent to API
  * 
  * @returns Object with key/value pairs
  */
  prepInputList(inputList) {
    try {
      const result = {};
      inputList.forEach(input => {
        if (input.inputValue !== undefined) {
          result[input.inputName] = input.inputValue;
        }
      });
      return result;
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Test a list of Smart Inputs data to discover if fields are required
  *
  * @returns Object with validation status and updated array of smart input objects
  */
  checkRequiredInputs(inputList) {
    try {
      let validationObj = {};
      inputList.forEach(input => {
        if (input.isRequired && (input.inputValue === undefined || input.inputValue === '')) {
          input.isEmpty = true;
          delete input.inlineMsg; // delete any previously displayed inline error message
        }
      });
    
      if (inputList.some(input => input.isRequired && (input.inputValue === undefined || input.inputValue === ''))) {
        validationObj = {
          isValid: false,
          inputList
        };
      }
      else {
        validationObj = {
          isValid: true,
          inputList
        };
      }
      return validationObj;
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Provides inline error message to Smart Input
  * 
  * @param inputName String input name
  *
  * @returns String input inline message
  */
  helperTextManage(inputName) {
    try {
      let message = '';
      if (inputName !== undefined) {
        const list = cloneDeep(this.state.inputList);
        const curInput = list.find(input => input.inputName === inputName);
        if (curInput) {
          curInput.inlineMsg
            ? message = curInput.inlineMsg
            : message = curInput.isEmpty ? REQUIRED : '';
        }
      }
      return message;
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Display error message in red
  * 
  * @param inputName String input name
  *
  * @returns Object with key/value pairs
  */
  helperErrorManage(inputName) {
    try {
      if (inputName !== undefined) {
        const list = cloneDeep(this.state.inputList);
        const curInput = list.find(input => input.inputName === inputName);
        if (curInput && curInput.inputName === inputName) {
          if (curInput.inputName === inputName && !curInput.isEmpty && curInput.inlineMsg === undefined) {
            return false;
          }
          else {
            return true;
          }
        }
      }
      else {
        return false;
      }
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Test a list of Smart Inputs to discover if they ALL match
  * 
  * @param {array[strings]} arrInputNames array list of smart input names to be tested
  * @param {string} inputProperty smart input property name to test, native type expressed in a string
  *
  * @returns boolean
  */
  allInputsHaveVal(arrInputNames, inputProperty) {
    try {
      const list = cloneDeep(this.state.inputList);
      const minifiedList = [];
      list.forEach(input => {
        arrInputNames.forEach(inputName => {
          if (input.inputName === inputName) {
            minifiedList.push(input);
          }
        });
      });
      return minifiedList.every(input => input[inputProperty] !== undefined && input[inputProperty] !== null);
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Add inline message to a specific input
  * 
  * @param input an input object
  */
  addInlineMsg(inputList, input) {
    try {
      const index = inputList.findIndex(inputList => inputList.inputName === input.inputName);
      inputList[index].inlineMsg = input.inlineMsg;
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Clears value of given inputs
  * 
  * @param Array array of input names
  *
  */
  clearGivenInputValues(inputList, givenInputs) {
    try {
      inputList.forEach(input => {
        givenInputs.forEach(givenInput => {
          if (givenInput === input.inputName) {
            input.inputValue = '';
          }
        });
      });
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Clears all value of all inputs, but inputs remain
  * 
  * @param Array array of input names
  *
  */
  clearAllInputValues(inputList) {
    try {
      inputList.forEach(input => {
        input.inputValue = '';
      });
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Update whether a Smart Input to be required or not.
  * This would occur in the componenDidUpdate() method
  * 
  * @param array inputList
  * @param string inputName
  * @param bool isRequired
  */
  updateInputRequirement(inputList, inputName, isRequired) {
    try {
      const input = this.findInputByName(inputName);
      input.isRequired = isRequired;
      this.setState({ inputList });
    }
    catch (error) {
      persistErrors(error);
    }
  }

  /**
  * Returns input object based on inputName provided
  * 
  * @param string inputName
  */
  findInputByName(inputName) {
    try {
      const { inputList, } = this.state;
      const input = inputList.find(input => input.inputName === inputName) || {};

      return input;
    }
    catch (error) {
      persistErrors(error);
    }
  }
  
}

export default SmartFormProvider;