import { lighten } from '@mui/material/styles';

export const coreColors = {
  primary: '#0EC47E',
  secondary: '#00A667',
  tertiary: '#5BD8A9',
  accent: '#5BD8A9',
  accent2: '#FFCF42',
  error: '#FF0000',
  text: '#404040',
};

export default {
  palette: {
    common: {
      warn: 'rgb(250, 229, 0)'
    },
    default: {
      main: '#FFF',
      light: '#FFF',
      dark: '#FFF',
    },
    primary: {
      main: coreColors.primary,
      light: lighten(coreColors.primary, 0.9),
      dark: coreColors.primary,
    },
    secondary: {
      main: coreColors.accent,
      light: lighten(coreColors.accent, 0.9),
    },
    accent2: {
      main: coreColors.accent2,
      light: lighten(coreColors.accent2, 0.85),
    },
    error: {
      main: coreColors.error
    },
    text: {
      main: coreColors.text
    },
    lightHeaderText: {
      main: coreColors.lightHeaderText
    },
    row: {
      trim: lighten(coreColors.primary, 0.9),
      hover: lighten(coreColors.primary, 0.8),
      selected: lighten(coreColors.primary, 0.6),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#FFF',
          backgroundColor: coreColors.primary,
          '&:hover': {
            color: '#FFF',
            backgroundColor: lighten(coreColors.primary, 0.4),
            boxShadow: 'none',
          },
          '&.MuiButton-containedSecondary': {
            color: coreColors.primary,
            backgroundColor: '#FFF',
            boxShadow: 'none',
            fontWeight: 'bold',
          },
          boxShadow: 'none',
        },
        outlined: {
          borderColor: coreColors.primary,
          borderRadius: '5px',
          '&.MuiButton-outlinedSecondary': {
            border: '1px solid #FFF',
          }
        },
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      }
    },
    MuiChip: {
      defaultProps: {
        color: coreColors.primary,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: lighten(coreColors.primary, 0.4),
            color: '#fff',
          },
        },
        deleteIcon: {
          color: '#fff',
          '&:hover': {
            color: 'rgb(215, 215, 215)',
          },
        },
        avatar: {
          backgroundColor: '#fff',
          color: coreColors.primary,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'auto', // allows modal content to scroll, if screen height becomes too small
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: 'none'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: 'none'
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          margin: '5px 0',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgb(0, 0, 0, .89)',
        }
      }
    },
    MuiInput: {
      contained: {
        color: '#FFF',
        borderRadius: '20px',
        backgroundColor: coreColors.primary,
        '&:hover': {
          color: '#FFF',
          backgroundColor: lighten(coreColors.primary, 0.4),
          boxShadow: 'none',
        },
        boxShadow: 'none',
      },
      outlined: {
        borderColor: coreColors.primary,
        borderRadius: '20px',
      },
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: coreColors.primary,
          },
          '&:before': {
            borderBottom: '1px solid #0EC47E'
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid #0EC47E',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#FAFAFA',
            color: '#0071CE'
          }
        }
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: { // makes menu anchor show up on anchor point rather than slightly below it
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          padding: '9px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: coreColors.accent,
          },
          '&.Mui-completed': {
            color: coreColors.accent,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          padding: '24px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: coreColors.primary,
          },
          indicatorColor: {
            color: coreColors.primary,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      top: '-20px'
    },
  },
  MuiInputLabel: {
    root: {
      top: '-20px'
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'PT Sans',
      'Arial',
      'sans-serif',
    ]
  },
  breakpoints: {
    values: { // all following values should be non-zero except in tests
      xs: 0,
      sm: parseInt(600),
      md: parseInt(768),
      lg: parseInt(1024),
    },
  }
};