import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import {
  TextField,
  InputAdornment,
} from '@mui/material';

import {
  IconBtnTooltip,
} from '@timber-rider/parkingtree-client-common';

export class PasswordInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,
    inputVariant: PropTypes.string,
  };

  state = {
    inputValue: '',
    isError: undefined,
    helperMsg: '',
    testId: '',

    isPasswordVisible: false,
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    this.context.addInput(this.context.inputList, inputName, isRequired);
    const testId = this.context.createTestId(inputName);
    
    this.setState({ testId, });
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputName, isRequired } = this.props;
    const { inputList, findInputByName, updateInputRequirement, } = this.context;
    const inputValue = findInputByName(inputName).inputValue;
    // value update, if inputList value updated from somewhere else
    if (prevState.inputValue !== inputValue) {
      const e = { target: { value: inputValue } };
      this.inputChange(e);
    }

    // for when the input requirement prop is updated dynamically
    if (prevProps.isRequired !== isRequired) {
      updateInputRequirement(inputList, inputName, isRequired);
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { 
      inputName, labelText, isDisabled, isReadOnly, inputFocus,
      inputFullWidth, autoComplete, className, style, inputVariant,
    } = this.props;

    const { inputValue, isError, helperMsg, isPasswordVisible, testId, } = this.state;

    return (
      <TextField
        name={inputName}
        type={isPasswordVisible ? 'text' : 'password'}
        value={inputValue}
        label={labelText}
        disabled={isDisabled}
        helperText={helperMsg || this.context.helperTextManage(inputName)}
        error={isError || this.context.helperErrorManage(inputName)}
        autoFocus={inputFocus || false}
        fullWidth={inputFullWidth || false}
        autoComplete={autoComplete || 'on'}
        style={style}
        variant={inputVariant || 'outlined'}
        className={className}
        onChange={e => this.inputChange(e)}
        inputProps={{
          maxLength: 200,
        }}
        InputProps={{ 
          readOnly: isReadOnly,
          'data-testid': testId,
          endAdornment: (
            <InputAdornment position='end'>
              <IconBtnTooltip
                icon={isPasswordVisible ? 'visibility' : 'visibilityOff'}
                onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                title='Toggle Password'
              />
            </InputAdornment>
          )
        }}
        key={inputName}
      />
    );
  }

  inputChange(e) {
    let inputValue = e.target.value.trimStart() || '';
    
    const { inputName } = this.props;
    const helperMsg = '';
    const isError = undefined;
    
    if (inputValue.length === 0) {
      inputValue = '';
    }
    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  }
  
}

export default PasswordInput;