import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import LoginForm from 'view/public/Login/LoginForm';

export class Login extends Component {

  render() {
    return (
      <SmartFormProvider>
        <LoginForm />
      </SmartFormProvider>
    );
  }
  
}

export default Login;