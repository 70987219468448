import React from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import { EVENT_REASON_TYPE } from 'utils/SmartForm/constants';

import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

export class LicensePlateInput extends React.Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    options: PropTypes.array,
    fieldChange: PropTypes.func,
  };

  state = {
    val: '',
    inputIndex: undefined,
    inputValue: '',
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;
  
  componentDidMount() {
    const { inputName, isRequired } = this.props;
    this.context.addInput(this.context.inputList, inputName, isRequired);
    const testId = this.context.createTestId(inputName);
    
    this.setState({ testId, });
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputName, isRequired } = this.props;
    const { inputList, findInputByName, updateInputRequirement, } = this.context;
    const inputValue = findInputByName(inputName).inputValue;
    // value update, if inputList value updated from somewhere else
    if (prevState.inputValue !== inputValue) {
      const e = { target: { value: inputValue } };
      this.inputChange(e);
    }

    // for when the input requirement prop is updated dynamically
    if (prevProps.isRequired !== isRequired) {
      updateInputRequirement(inputList, inputName, isRequired);
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const { inputName, labelText, isDisabled, isReadOnly, style, options } = this.props;
    const { inputValue, isError, helperMsg } = this.state;
    return (
      <Autocomplete
        freeSolo //Removes the 'No options' from list
        options={options}
        onChange={(e, obj) => this.handleSelect(e, obj)}
        onInputChange={(e, inputValue, reason) => this.handleEntry(e, inputValue, reason)}
        onBlur={() => this.handleNoMatch()}
        inputValue={inputValue || ''}
        disabled={isDisabled || isReadOnly}
        renderInput={params => (
          <TextField
            {...params}
            name={inputName}
            disabled={isDisabled} // need this here to overwrite disable from Autocomplete params that are passed
            label={labelText}
            onChange={e => this.inputChange(e)}
            helperText={helperMsg || this.context.helperTextManage(inputName)}
            error={isError || this.context.helperErrorManage(inputName)}
            style={style}
            inputProps={{
              ...params.inputProps,
              maxLength: 8,
            }}
            InputProps={{
              ...params.InputProps,
              readOnly: isReadOnly,
            }}
            variant='outlined'
          />
        )}
        key={inputName}
        data-testid={inputName}
      />
    );
  }

  inputChange = e => {
    let inputValue = e.target.value?.trimStart() || '';

    const { inputName } = this.props;
    let helperMsg = '';
    let isError = undefined;
    let isValid = true;
    
    const regex = /^[a-z0-9]+$/i; // allows alpha-numeric values

    if (inputValue.length === 0) {
      inputValue = '';
      if (this.props.fieldChange) this.props.fieldChange('both');
    }
    else if (regex.test(inputValue)) {
      isValid = false;
      helperMsg = '';
      isError = undefined;
      if (this.props.fieldChange) this.props.fieldChange('plate');
    }
    else if (!regex.test(inputValue)) {
      helperMsg = 'Only alphanumeric allowed'; // eslint-disable-line
      isError = true;
      inputValue = this.state.inputValue;
    }
    if (inputValue && inputValue.length > 0) {
      inputValue = inputValue.toUpperCase();
    }

    this.setState(
      { inputValue, helperMsg, isError },
      this.context.updateInput(this.context.inputList, inputName, inputValue, isValid)
    );
  };

  handleSelect(e, obj) {
    const { inputName } = this.props;
    let inputValue;
    if (obj) {
      inputValue = obj;
      this.context.updateInput(this.context.inputList, inputName, inputValue);
    }
    else {
      this.context.updateInput(this.context.inputList, inputName, '');
    }
  }

  handleEntry(e, inputValue, reason) {
    const { inputName } = this.props;
      
    if (reason === EVENT_REASON_TYPE.UPDATE) {
      // do nothing
    }
    if (reason === EVENT_REASON_TYPE.RESET && inputValue !== this.state.inputValue && inputValue !== '') {
      this.setState({ inputValue });
    }
    else if (reason === EVENT_REASON_TYPE.CLEAR && inputValue === '') {
      this.setState(
        { inputValue: '' },
        this.context.updateInput(this.context.inputList, inputName, '', true)
      );
    }
    else if (reason === EVENT_REASON_TYPE.INPUT) {      
      this.setState(
        { inputValue },
        this.context.updateInput(this.context.inputList, inputName, inputValue, true)
      );
    }
  }

  handleNoMatch() {
    if (this.state.inputValue === '') {
      const { inputName } = this.props;
      const helperMsg = '';
      const isError = undefined;
      const isValid = true;
      const inputValue = '';

      this.setState(
        { inputValue, helperMsg, isError },
        this.context.updateInput(this.context.inputList, inputName, inputValue, isValid)
      );
    }
  }

}

export default LicensePlateInput;
