import { combineReducers } from '@reduxjs/toolkit';

import { INITIALIZE_STORE } from './view/constants';

import Session from 'view/reducer';
import Alerts from 'utils/components/alerts/reducer';

// Ability to reinitialize the entire store. This action type is used by logout
const rootReducer = (state, action) => {
  if (action.type === INITIALIZE_STORE) {
    state = {};
  }  
  return appReducer(state, action);
};

const appReducer = combineReducers({
  session: Session,
  alerts: Alerts,
});

export default rootReducer;