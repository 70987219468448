import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@mui/material';

import { coreColors } from 'utils/theme/parking_tree';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import SessionMenu from 'view/private/footer/SessionMenu';

import { userLogout } from 'view/actions';

import { addAlert } from 'utils/components/alerts/actions';

const select = state => ({
  isAddingPermit: state.session.isAddingPermit,
});

export class Footer extends Component {

  static propTypes = {
    isAddingPermit: PropTypes.bool.isRequired,
    userLogout: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
    handleSessionMenu: PropTypes.func.isRequired,
    isSessionOpen: PropTypes.bool.isRequired,
  };

  state = {
    menuSelected: '',
  };

  render() {
    if (this.props.isAddingPermit) {
      return null;
    }
    const { menuSelected } = this.state;
    return (
      <React.Fragment>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            value={menuSelected} 
            onChange={menuSelected => this.setState({ menuSelected })}
            style={{ backgroundColor: coreColors.primary }}
          >
            <BottomNavigationAction
              value={menuSelected}
              icon={<AccountCircleIcon style={{ fontSize: '2.0rem' }} />}
              style={{ color: '#fff' }}
              onClick={() => this.props.handleSessionMenu()}
            />
          </BottomNavigation>          
        </Paper>
        <SessionMenu 
          isSessionOpen={this.props.isSessionOpen}
          handleSessionMenu={() => this.props.handleSessionMenu()}
          logOut={() => this.logOut()}
        />
      </React.Fragment>
    );
  }

  logOut() {
    this.props.userLogout();
    this.props.history.push('/');
    this.props.addAlert('success', 'You have successfully logged out.');
  }

  handleMenu(selection) {
    const currentSelection = this.state[selection];
    this.setState({ [selection]: !currentSelection });
    this.props.handleSessionMenu();
  }
}

export default connect(select, {
  userLogout,
  addAlert,
})(withRouter(Footer));