import { cloneDeep } from 'lodash';
import { fakePlateNumberList, fakeVinNumberList, fakePropertyList, fakeSearchResults } from 'view/private/body/FakeData';
import dayjs from 'dayjs';

import {
  LOGIN,
  AUTHENTICATE_ACCOUNT,
  BROWSER_WARNING,
  MOBILE_WARNING,
  LANGUAGE_CHANGE,
  LANGUAGES,
  TOGGLE_PERMIT_ADD,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
  CREATE_PASSWORD,
  SEARCH_VEHICLE,
} from './constants';

const initialState = {
  isAuthenticated: false,
  isAddingPermit: false,
  isLoader: false,
  language: LANGUAGES.EN,
  token: '',
  userDetails: {},
  errors: [],

  vehicleInfo: {},

  plateNumberList: fakePlateNumberList,
  vinNumberList: fakeVinNumberList,
  propertyList: fakePropertyList,
  searchResults: fakeSearchResults,
};

export default function Session(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case CREATE_PASSWORD:
    case LOGIN: { 
      const payload = action.payload;
      // newState.errors = [];
      // newState.token = action.payload.data.Token;
      if (payload.email === 'b@a.com' && payload.password === 'gosteep') {
        newState.isAuthenticated = true;
        newState.userDetails.name = 'Bruce Amberson';
      }
      else if (payload.email === 'k@a.com' && payload.password === 'gosteep') {
        newState.isAuthenticated = true;
        newState.userDetails.name = 'Bruce Amberson'; 
      }
      return newState;
    }

    case AUTHENTICATE_ACCOUNT: {
      newState.isAuthenticated = true;
      return newState;
    }
    
    case BROWSER_WARNING: {
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;
    }

    case MOBILE_WARNING: {
      newState.showMobileWarning = !state.showMobileWarning;
      return newState;
    }

    case LANGUAGE_CHANGE: {
      newState.language = action.payload;
      return newState;
    }

    case TOGGLE_PERMIT_ADD: {
      newState.isAddingPermit = action.payload;
      return newState;
    }

    case TOGGLE_LOADER_ON: {
      newState.isLoader = true;
      return newState;
    }

    case TOGGLE_LOADER_OFF: {
      newState.isLoader = false;
      return newState;
    }

    case SEARCH_VEHICLE: {
      newState.vehicleInfo = action.payload;
      const property = newState.propertyList.find(property => property.property_id === newState.vehicleInfo.property);
      if (property) {
        newState.vehicleInfo.property_name = property.property_name;
        newState.vehicleInfo.searchTimeStamp = dayjs().format('MM-DD-YY @ hh:mm A');
      }
      return newState;
    }
    
    default: 
      return newState;
  }
}