import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route } from 'react-router-dom';

import Login from 'view/public/Login';
import ForgotPassword from 'view/public/ForgotPassword';
import CreatePassword from 'view/public/CreatePassword';

export class PublicRoutes extends Component {

  static propTypes = {
    isLoader: PropTypes.bool.isRequired,
  };

  render() {
    const pointerEvents = this.props.isLoader ? 'none' : 'auto';
    return (
      <div style={{ pointerEvents }}>
        <Switch>
          <Route exact path='/' render={props => (<Login {...props} />)} />
          <Route path='/create-password' render={props => (<CreatePassword {...props} />)} />
          <Route exact path='/forgot-password' render={props => (<ForgotPassword {...props} />)} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(PublicRoutes);